<template>
    <div class="about-wrapper">
        <navbar></navbar>
        <div class="about-body">
            <div class="flex">
                <img src="../assets/pc/about/about.png" class="about-img"/>
                <div>
                    <div class="title">关于优品利购</div>
                    <div class="content">优品利购是一款社交分享智能导购APP，通过与全国数家大型电商平台与海量商家合作，以高质量的消费类内容向用户介绍高性价比、好口碑的商品。新版的优品利购增加了外卖点餐、美食旅行、本地生活，飞猪机票等服务，满足您衣食住行各方面的需求。</div>
                    <div class="flex">
                        <div id="qrcode" class="code-img"></div>
                        <div>
                            <div :class="isIOS ? 'mobile-block mobile-block-selected' : 'mobile-block mobile-block-notselected'" @click="handleChangeMobile('ios')">
                                <img :src="isIOS ? require('../assets/pc/home/IOS_red.png') : require('../assets/pc/home/IOS_white.png')" class="mobile-img"/>
                                iPhone版下载
                            </div>
                            <div :class="isIOS ? 'mobile-block mobile-block-notselected' : 'mobile-block mobile-block-selected'" @click="handleChangeMobile('android')">
                                <img :src="isIOS ? require('../assets/pc/home/android_white.png') : require('../assets/pc/home/android_red.png')" class="mobile-img"/>
                                Android版下载
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <record color="#ffffff"></record>
        </div>
    </div>
</template>

<script>
    import {getVersionLink} from '@/api/requestApi.js'

    import navbar from "./navbar";
    import record from "./record";

    import QRCode from 'qrcodejs2'
    export default {
        name: "about",
        components:{
            navbar,
            record
        },
        data(){
            return {
                isIOS: true,
                qrcode: null,
                iosLink: '',
                androidLink: ''
            }
        },
        mounted(){
            this.getLink();
        },
        methods:{
            getLink(){
                getVersionLink().then((data)=>{
                    this.iosLink = data.iosLink;
                    this.androidLink = 'https://mall.buytk.com/#/download';

                    this.qrcode = new QRCode(document.getElementById('qrcode'), {
                        text: data.iosLink, //要生成二维码的网址
                        width: 120,  //图像宽度
                        height: 120, //图像高度
                        colorDark: '#000000',  //前景色
                        colorLight: '#ffffff', //背景色
                        margin: 0, //外边距
                        correctLevel: QRCode.CorrectLevel.L //容错级别。属性值有：QRCode.CorrectLevel.L、QRCode.CorrectLevel.M、QRCode.CorrectLevel.Q、QRCode.CorrectLevel.H
                    })
                })
            },
            handleChangeMobile(val){
                if(val === 'android'){
                    this.isIOS = false;
                    this.qrcode.makeCode(this.androidLink);
                }else{
                    this.isIOS = true;
                    this.qrcode.makeCode(this.iosLink);
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .flex{
        display: flex;
        align-items: center;
    }
    @mixin width-height($width: 100%, $height: 100%){
        width: $width;
        height: $height;
    }
    @mixin font($size: 54px,$color:#fff,$weight:500){
        font-size: $size;
        color: $color;
        font-weight: $weight;
    }
    @mixin flex($ai:center){
        display: flex;
        align-items: $ai;
    }
    .about-wrapper{
        @include width-height;
        display: flex;
        flex-direction: column;

        .about-body{
            flex: 1;
            background-image: url('../assets/pc/about/bg.jpg');
            background-size:100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .about-img{
                @include width-height(600px,600px);
                margin-right: 40px;
            }
            .title{
                @include font;
                margin-bottom: 40px;
            }
            .content{
                width: 520px;
                @include font(18px,#fff,300);
                line-height: 36px;
                margin-bottom: 40px;
            }
            .code-img{
                @include width-height(150px,150px);
                background-color: #fff;
                border-radius: 20px;
                @include flex;
                justify-content: center;
                margin-right: 40px;
            }
            .mobile-block{
                font-size: 18px;
                font-weight: 500;
                padding: 12px 20px;
                border-radius: 22px;
                margin-bottom: 15px;
                @include flex;
                cursor: pointer;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            .mobile-block-selected{
                border: none;
                color: #FA0655;
                background: #ffffff;
            }
            .mobile-block-notselected{
                border: 1px solid #ffffff;
                color:#ffffff;
                background: transparent;
            }
            .mobile-img{
                @include width-height(20px,20p);
                margin-right: 10px;
            }
        }
    }
</style>
